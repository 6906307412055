.gridWrapper > :first-child {
    width: 800px;
    height: 500px;
  }

  .tablecells {
    font-size: '10px';
  }

  .Clients {
    margin-left: 1em;
  }

  #headers {
    margin-top: 10vh;
    margin-bottom: 5vh;
    margin-right: 5vw;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  #headers div {
    width: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#headers div img {
    width: 1.5vw;
    cursor: pointer;
}