.dashboard {
  font-family: 'Roboto';
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  font-style: normal;
}

.dd {
  height: 40%;
  width: 100%;
}

.left-menu {
  background-color: #f8fcff;
}

.Left-menu-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.status {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

#graph p {
  font-size: 0.9rem !important;
}

#graph h4 {
  font-size: 1rem !important;
}

.welcome {
  margin-top: 9px;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #6b6b6b;
}

.company {
  margin-top: 17px;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #4459c6;
}

#toDoList {
  margin: 0;
}

#toDoList li {
  padding: 0;
}

.Dashboard #toDoList * {
  font-size: 100% !important;
}

#cardInfo * {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .Dashboard #toDoList * {
    font-size: 50% !important;
  }

  #cardInfo * {
    font-size: 50% !important;
  }

  #graph p {
    font-size: 0.5rem !important;
  }

  #graph h4 {
    font-size: 0.7rem !important;
  }
  .dd {
    height: 40%;
  }

  .card {
    width: 20%;
    height: 30%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 601px) {
  .Dashboard #toDoList * {
    height: 75%;
    font-size: 80% !important;
  }
  #cardInfo * {
    height: 40%;
    font-size: 90% !important;
  }

  #graph p {
    font-size: 0.7rem !important;
  }

  #graph h4 {
    font-size: 1rem !important;
  }
  .dd {
    height: 40%;
  }

  .card {
    width: 20%;
    height: 30%;
  }
}
