@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
h2 {
  font-weight: bold;
  display: inline;
  width: inherit;
}

a {
  font-weight: bold;
}

.Login {
  font-family: 'Roboto';
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: row;
  font-style: normal;
}

.left-menu {
  background-color: rgba(81, 215, 185, 0.2);
}

.Left-menu-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
}

.no-account {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 120%;
  font-weight: 500;
  line-height: 23px;
  color: #6b6b6b;
}

.sideBar {
  background: rgba(81, 215, 185, 0.2);
  height: 100%;
  padding: 2% 0;
}

.logoImg {
  width: 12vw;
  margin-right: 1%;
}

.imgCreate {
  width: 50px;
  opacity: 0.7;
}

.computerImg {
  width: auto;
  height: 50%;
}

.welcomeMsg {
  width: 80%;
  height: 25%;
  display: flex;
  align-items: flex-end;
  font-weight: 300;
  font-size: 170%;
  color: #828282;
}

.formLogin {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  width: 80%;
  height: 60%;
}

.formLogin .buttonLogin {
  width: 25vw;
  height: 20%;
}

.formLogin .buttonLogin button {
  width: 50%;
  height: 50%;
  font-size: 150%;
  font-weight: 500;
  white-space: nowrap;
  border: 0;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.formLogin .buttonLogin .buttonConnect {
  background: #f38ab0;
  color: white;
  display: flex;
  justify-content: flex-start;
}

.formLogin .buttonLogin .buttonGoogle {
  background: #f38ab0;
  color: white;
}

.formLogin .buttonLogin button:hover {
  filter: brightness(90%);
}

.formLogin .buttonLogin button:focus {
  outline: none;
}

.whiteArrow {
  width: 30px;
  height: 60px;
  justify-self: flex-end;
  position: absolute;
  right: 5%;
}

.formLogin .emailInput {
  background: rgba(231, 235, 255, 0.7);
  width: 90%;
}

.formLogin .passwordInput {
  width: 90%;
  background: rgba(231, 235, 255, 0.7);
}

@media screen and (max-width: 600px) {
  .logoImg {
    width: 25px;
    margin-right: 1vw;
  }

  .companyLogo p {
    height: 25px !important;
  }

  .sideBar {
    padding: 5% 0;
  }

  .imgCreate {
    width: 15px;
    height: 100%;
  }

  .computerImg {
    width: 100px;
    height: 100px;
  }

  .no-account {
    font-size: 40%;
  }

  .welcomeMsg {
    font-size: 50%;
    height: 5%;
  }

  .formLogin .buttonLogin {
    height: 25%;
    width: 100%;
  }

  .formLogin .buttonLogin button {
    width: 80%;
    height: 30%;
    font-size: 100%;
    border-radius: 5px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .imgCreate {
    width: 30px;
    height: 90%;
  }

  .no-account {
    font-size: 80%;
  }

  .welcomeMsg {
    font-size: 100%;
  }

  .formLogin .buttonLogin button {
    width: 20vw;
    border-radius: 10px;
    font-size: 90%;
  }
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .formLogin .buttonLogin button {
    width: 20vw;
    border-radius: 10px;
    font-size: 130%;
  }
}
