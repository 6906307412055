h1 {
  font-family: "Roboto";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #4459c6;
}

h2 {
  font-family: "Roboto";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

h3 {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.dashboard {
  font-family: "Roboto";
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  font-style: normal;
}

.left-menu {
  background-color: #f8fcff;
}

.Left-menu-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.status {
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.company {
  margin-top: 17px;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #4459c6;
}

.main-page {
  margin-left: 40px;
}

.h1-margin {
  margin-bottom: 40px;
  padding-left: 20px;
  width: fit-content;
}

.top-cards {
  margin-bottom: 100px;
  height: 40%;
}

.maia-text {
  font-family: "Roboto";
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  color: #828282;
}

.cards {
  margin: 1em;
  width: 30%;
  min-width: 20rem;
  max-width: 30vw;
  min-height: 13em;
  max-width: 60vh;
}

.params {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}

.size_img {
  width: 100%;
  height: 20%;
  min-width: 100px;
  padding: auto;
}

.img {
  display: "flex";
  flex-direction: "row";
  align-items: flex-start;
  flex-wrap: "wrap";
  justify-content: "space-around";
  width: 75%;
}
